.about-page {
  .bottom-tags {
    display: none;
  }
}
.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: auto;
}

.flat-button {
  color: #8493ce;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #8493ce;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #8493ce;
    color: #333;
  }
}
.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 #ffffe0;
  }

  .face1 {
    transform: translateZ(100px);
  }

  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }

  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  }

  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }

  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  }

  .face6 {
    transform: rotatex(-90deg) translateZ(100px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateZ(90deg);
  }
  83% {
    transfrom: rotateX(90deg);
  }
}

// /*(1000px and down) */
// @media only screen and (max-width: 1000px) {
//   .about-page {
//     .text-zone {
//       left: 15%;
//       width: 80%;
//     }

//     .stage-cube-cont {
//       left: 75%;
//       top: -15%;
//     }
//   }
// }

/*(1000px and down) */
@media only screen and (max-width: 1000px) {
  .about-page {
    .text-zone {
      left: 10%;
      width: 75%;
      top: 50%;
    }

    .stage-cube-cont {
      display: none;
    }
  }
}
