.skills-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 25%;
    transform: translateY(-50%);
    width: 100%;
    vertical-align: middle;
    display: table-cell;
    max-height: 100%;
    white-space: nowrap;

    h1 {
      font-size: 53px;
      font-family: "Coolvetica";
      color: #8493ce;
      font-weight: 400;
      margin-top: 25rem;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      &::before {
        content: "<h1>";
        font-family: "La Belle Aurore";
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &::after {
        content: "<h1/>";
        font-family: "La Belle Aurore";
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    .project-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 5rem;
      .project-cards {
        margin-top: 0 !important;
      }
      .card {
        width: 65%;
        margin-top: 2rem;
        background: #8493ce86;
        color: #fff;
        font-family: "Coolvetica";

        .project-des {
          font-size: 5px;
        }
        .project-images {
          height: inherit;
          width: fit-content;
        }
        .card-text {
          font-size: 1.5rem;
          font-family: sans-serif;
        }
        .card-title {
          font-family: "Coolvetica";
          color: #c4cbe9;
        }
        .card-body {
          padding: 0;
        }
        .project-links {
          display: flex;
          justify-content: space-around;
          margin-top: 4rem;
          margin-right: 1rem;

          .project-link-icons {
            height: 3.5rem;
            color: #8090cf;
          }

          .project-link-icons:hover {
            cursor: pointer;
            color: black;
          }

          .hide {
            opacity: 0;
            margin-right: 2rem;
          }
        }
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }

  .bottom-tags .tags {
    color: #8493ce;
  }
}

.modal-content {
  background: #8493ce;
  font-weight: 400;

  .modal-title {
    font-size: 4rem;
    font-family: "Coolvetica";
    color: #8493ce;
  }
  .modal-footer,
  .modal-header {
    justify-content: center;
    font-size: 3rem;
    background: #115173;
    color: black;

    .tool-list {
      font-size: 2rem;
      border: solid 1px black;
      border-radius: 5px;
      padding: 0.5rem;
      background: #8493ce;
    }
    .tool-list:hover {
      background: #022c43;
      color: #fff;
    }
  }

  .modal-body {
    font-size: 2rem;
    font-weight: 400;
    color: #000;
  }
}
.modal-btn {
  border-radius: 0;
  width: 100%;
  height: 100%;
  font-size: 3rem;
  background: #115173;
  border: solid black 1px;
}
.modal-btn:hover {
  background: #8493ce;
  border-color: #115173;
}

/*(1000px and down) */
@media only screen and (max-width: 1000px) {
  .skills-page {
    .text-zone {
      left: 10%;
      top: 20%;
      width: auto;

      .project-container {
        margin-left: 5rem;
        width: auto;

        .g-5,
        .gx-5 {
          --bs-gutter-x: 0;
        }
      }

      h1 {
        left: 0;
      }
    }
  }
}
