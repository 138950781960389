.contact-form {
  width: 100%;
  margin-top: 15px;

  ul {
    padding: 0;
    margin: 0;
    top: 28rem;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: auto;
      display: block;
      position: relative;
      opacity: 0;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
      clear: both;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type="text"],
  input[type="email"] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    min-height: 150px;
    box-sizing: border-box;
  }

  .flat-button {
    color: #8493ce;
    background: 0 0;
    font: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #8493ce;
    float: right;
    border-radius: 4px;
  }
}
.flat-button:hover {
  cursor: pointer;
  background: #115173;
}

.contact-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 20%;
    transform: translateY(-50%);
    width: auto;
    vertical-align: middle;
    display: table-cell;
    max-height: 100%;
    white-space: nowrap;

    h1 {
      font-size: 53px;
      font-family: "Coolvetica";
      color: #8493ce;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      &::before {
        content: "<h1>";
        font-family: "La Belle Aurore";
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &::after {
        content: "<h1/>";
        font-family: "La Belle Aurore";
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 2rem;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .emailSuccess {
      color: #8493ce;
      animation: pulse 1s;
      border: solid 1px;
      border-radius: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      padding: 1rem;
    }

    .emailToggle {
      display: none;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

// /*(1000px and down) */
// @media only screen and (max-width: 1000px) {
//   .contact-page {
//     .text-zone {
//       left: 12%;
//       width: 80%;
//       white-space: normal;
//     }
//   }
// }

/*(1000px and down) */
@media only screen and (max-width: 1000px) {
  .container {
    top: 15%;
  }
  .contact-page {
    .text-zone {
      left: 10%;
      width: 75%;
      white-space: normal;

      p {
        font-size: 1.5rem;
      }

      h1 {
        font-size: 4rem;
      }

      .contact-form {
        position: absolute;

        .flat-button{
          margin-top: 0;
        }
      }
    }
  }
  .tags {
    bottom: -12%;
  }
}
