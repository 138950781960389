.home-page {
  .skills-zone {
    position: absolute;
    left: 65%;
    top: 50%;
    transform: translateY(-50%);
    max-height: 90%;
    text-align: center;
    font-size: 2rem;

    .skills {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .skill {
      border: solid #8493ce 1px;
      border-radius: 1px;
      margin: 0.5rem;
      white-space: nowrap;
      color: #8493ce;
      margin-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .skills-title {
      color: #fff;
    }
    .skills-hr {
      color: #8493ce;
    }
  }
  .text-zone {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 90%;
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica";
    font-weight: 400;

    &::before {
      content: "<h1>";
      font-family: "La Belle Aurore";
      color: #8493ce;
      font-size: 18px;
      position: absolute;
      margin-top: -25px;
      opacity: 0.6;
    }

    &::after {
      content: "</h1>";
      font-family: "La Belle Aurore";
      color: #8493ce;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 8px;
      animation: fadeIn 1s 1.7sa backwards;
    }

    img {
      width: 32px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8493ce;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #8493ce;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #8493ce;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #8493ce;
      color: #333;
    }
    &:nth-last-of-type(1) {
      margin-left: 2rem;
    }
  }
}

// /*(1000px and down) */
// @media only screen and (max-width: 1000px) {
//   .home-page {
//     .skills-zone {
//       left: 15%;
//       top: 95%;
//       width: 100%;

//       .skill {
//         white-space: normal;
//       }
//     }
//   }
// }

/*(1000px and down) */
@media only screen and (max-width: 1000px) {
  .home-page {
    .tags {
      left: 5%;
      top: 0%;
    }

    .text-zone {
      top: 45%;
      width: auto;
      left: 10%;

      h1 {
        font-size: 4rem;
      }
    }
    .skills-zone {
      left: 10%;
      top: 110%;
      font-size: 1.5rem;
      width: 80%;

      .skill {
        white-space: normal;
      }
    }

    .home-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .flat-button:nth-last-of-type(1) {
        margin-left: 0;
      }
    }
  }
}
